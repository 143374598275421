.paper {
  position: relative;
  overflow: hidden;
  padding: 50px !important;
  margin: 50px !important;

  @media only screen and (max-width: 650px) {
    padding: 20px !important;
    margin: 0 !important;

    height: 100% !important;
  }
}

.image {
  max-width: 50%;

  &__container {
    opacity: 0.3;
    position: absolute;
    width: 100%;
    height: auto;
    text-align: center;
  }
}

.container {
  padding: 100px;
  @media only screen and (max-width: 650px) {
    padding: 20px;
  }
}

p {
  padding: 0;
  margin: 0;
}

h3 {
  padding: 5px 0;
  margin: 5px 0;
}
