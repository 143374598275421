@import './assets/styles/colors';
@import './assets/styles/components';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Ropa+Sans:ital@0;1&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  font-family: 'Open Sans', sans-serif !important;
}

p {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: initial !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Ropa Sans' !important;
}

button {
  font-family: 'Open Sans', sans-serif !important;
}

// In order to avoid the focus zoom on iPhone Safari the font must be at least 16px
input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// }
