button {
  transition: all 0.3s !important;

  &:hover {
    transform: translateY(-0.1em);
  }

  &:active,
  &:focus {
    transform: translateY(0.05em);
  }
}
