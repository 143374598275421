.notfound {
  width: 100%;
  height: 90%;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 60%;
  }

  &__title {
    margin: 20px;
  }

  &__body {
    margin: 10px;
  }

  &__button {
    margin: 10px;
    padding: 10px 20px 10px 20px;
    color: white;
    background-color: var(--color-blue-light, white);
    transition: all 0.2s;

    &:hover {
      transform: scale(1.1, 1.1);
      color: white;
      background-color: var(--color-blue-light, white);
    }
  }
}
