#tree {
  height: 91vh;
  margin: 10px;
  border-radius: 20px;

  @media screen and (max-width: 800px) {
    height: 75vh;
  }
}

.bottom-toolbar {
  position: fixed;

  z-index: 250;
  bottom: 2.3em;
  width: 100%;
  text-align: center;

  &__center {
    background: var(--color-blue-light, #313331) !important;
    box-shadow: 0 2px 5px 1px #b5b5b5 !important;
  }

  &__zoom-in {
    background: var(--color-blue-light, #313331) !important;
    box-shadow: 0 2px 5px 1px #b5b5b5 !important;
  }

  &__zoom-out {
    background: var(--color-blue-light, #313331) !important;
    box-shadow: 0 2px 5px 1px #b5b5b5 !important;
  }

  & > button {
    box-shadow: 0 2px 5px 1px rgba(181, 181, 181, 0.7) !important;
  }

  &__menu-button {
    background-color: var(--color-blue-light, white);
    color: white;
    padding: 7px 15px 7px 15px;

    &:hover {
      background-color: var(--color-blue-light, white);
    }
  }
}

.node {
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__label {
    text-align: center;
    margin: 40px;
    font-weight: bolder !important;
  }
}

.node circle {
  stroke-width: 10px;
}

.node text {
  font: 30px sans-serif;
  font-weight: 600;
  background-color: yellow;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.node--internal text {
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
}

#searchnode {
  visibility: hidden;
  height: 0;
  padding: 0;
  margin: 0;
}

.searchBox {
  position: absolute;
  background-color: rgb(49, 51, 49);
  height: 40px;
  border-radius: 40px;
  padding: 10px;

  &:hover > .searchInput {
    width: 240px;
    max-width: 240px;
    padding: 0 6px;
  }

  &:hover > .searchButton {
    background: white;
    background-color: rgb(49, 51, 49);
  }

  &:hover > .closeButton {
    background: transparent;
  }
}

.MuiSvgIcon-colorAction {
  color: white;
}

.searchButton {
  color: white;
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(49, 51, 49);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}

.closeButton {
  background: transparent;
  margin-right: 5px;
  border: none;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;
}

@media screen and (max-width: 620px) {
  .searchBox:hover > .searchInput {
    width: 150px;
    padding: 0 6px;
  }
}

.nodes {
  fill: darkgray;
}
.nodes text {
  fill: white;
}

.link {
  fill: none;
  stroke: black;
  stroke-width: 30;
}

.animate {
  stroke-dasharray: 30;
  animation: dash 4s linear infinite;
  -webkit-animation: dash 4s linear infinite;
  -moz-animation: dash 4s linear infinite;
  -ms-animation: dash 4s linear infinite;
  -o-animation: dash 4s linear infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 500;
  }
  100% {
    stroke-dashoffset: 1000;
  }
}

.snackbar {
  background: var(--gradient-lr-blue, white);
  display: flex;
  justify-content: center;
}

.tooltip {
  padding: 0;
  margin: 0;

  &__header {
    padding: 5px !important;
    margin: 0 !important;

    & > h3 {
      text-align: center;
    }
  }

  &__content {
    padding: 10px !important;
    margin: 0 !important;
  }
}

@keyframes pulseMe {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes border-pulsate {
  0% {
    border-color: rgba(0, 0, 0, 1);
  }
  50% {
    border-color: rgba(0, 0, 0, 0.5);
  }
  100% {
    border-color: rgba(0, 0, 0, 1);
  }
}

.pulseMe {
  -webkit-animation: pulseMe 1s ease infinite, border-pulsate 1s ease infinite;
  animation: pulseMe 1s ease infinite, border-pulsate 1s ease infinite;
}

.blur {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.shadow {
  -webkit-filter: drop-shadow(0px 0px 5px white);
  filter: drop-shadow(0px 0px 5px #000); /* Same syntax as box-shadow */
}

.highlightMe {
  background: #393e46;
  border-color: #95d904;
}

@keyframes highlight {
  0% {
    border-color: #95d904;
    transform: scale(1);
  }

  70% {
    border-color: black;
    transform: scale(0.9);
  }

  100% {
    border-color: #95d904;
    transform: scale(1);
  }
}
